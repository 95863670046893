import * as React from 'react'
import PropTypes from 'prop-types'
import joinClasses from '/src/functions/joinClasses'

const ToggleButton = ({
    variant,
    size,
    toggleState,
    onClick,
    className,
    children
}) => {
    const classes = [
        'btn',
        ...(variant ? [`btn-${variant}`] : ['btn-primary']),
        ...(size ? [`btn-${size}`] : []),
        ...(toggleState === false
            ? ['btn-plus']
            : toggleState === true
            ? ['btn-minus']
            : []),
        ...(className ? [className] : [])
    ]

    const concatenatedClasses = joinClasses(classes)

    return (
        <button className={concatenatedClasses} onClick={onClick}>
            {children}
        </button>
    )
}

ToggleButton.propTypes = {
    /**
     * Specify a different style variant
     */
    variant: PropTypes.oneOf(['primary', 'secondary', 'inverse', 'feature']),
    /**
     * Optional set alternate button size
     */
    size: PropTypes.oneOf(['sm', 'lg']),
    /**
     * Toggle state
     */
    toggleState: PropTypes.bool,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired,
    /**
     * onClick function
     */
    onClick: PropTypes.func
}

ToggleButton.defaultProps = {
    variant: 'primary',
    children: 'Button title'
}

export default ToggleButton
